import React from "react"; // react hooks

import { Col, Row } from "antd";
import LayoutContent from "../components/Layout";
import ContactForm from "../components/ContactForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Home = () => {
  return (
    <LayoutContent style={{ height: "100%" }}>
      <Row
        id="inicio"
        style={{ padding: 50, minHeight: "100vh" }}
      >
        <Row>
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <h1>ALEXANDRE RELVAS ADVOCACIA</h1>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <h3>ASSÍSTÊNCIA JURÍDICA ESPECIALIZADA</h3>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <h5>
              Fundado em 1991, atua nas áreas Cível, Educacional, Imobiliária,
              Responsabilidade Civil, Consumidor, e, principalmente na área do
              Direito Médico, orientando e defendendo os profissionais
              médicos/odontólogos. A equipe de advogados está gabaritada para
              ajudar o profissional médico tanto na fase Pré-processual, quanto
              na fase processual. Nossos advogados estão preparados para
              orientar o profissional numa profilaxia jurídica, na elaboração de
              documentação médica/odontológica para melhorar a relação
              médico-paciente evitando eventuais processos, como também está
              qualificada e preparada para defender o profissional em todas as
              fases do processo.
            </h5>
          </Col>
        </Row>
      </Row>
      <Row
        id="sobre"
        style={{ minHeight: "100vh", paddingTop: 10, paddingBottom: 10 }}
      >
        <Row type="flex" justify="space-around" align="middle">
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={8}
            style={{ paddingBottom: 10 }}
          >
            <img alt="" className="imgDesktop" src="./assets/foto.png" />
            <img alt="" className="imgMobile" src="./assets/fotoMobile1.png" />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xxl={16}>
            Alexandre Relvas é advogado com 28 anos de experiência. Pós graduado
            em direito Civil e Processo Civil, bem como, especialista pela EMERJ
            (Escola de Magistratura do Estado do Rio de Janeiro) em Direito
            Médico.
            <br />
            Sua atuação permeia diversas áreas do Direito com ênfase em Direito
            Civil, Direito Educacional, Direito Imobiliário, Responsabilidade
            Civil, Direito do Consumidor.
            {/* <br /> Entretanto o direito Médico vem dedicando uma melhor atenção,
            com atuação tanto na documentação pré-processual, bem como, na
            defesa do profissional no âmbito processual.
            <br /> É conselheiro da OAB/Leopoldina RJ nos triênios de: <br />•
            2010/2012 <br />• 2013/2015 <br /> • 2016/2018 <br /> • 2019/2021
            <br /> No Triênio de 2016/2018 foi Presidente Coordenador das
            Comissões Temáticas da OAB/ Leopoldina RJ.
            <br /> Atualmente é Tesoureiro da OAB/ Leopoldina RJ. */}
          </Col>
        </Row>
        <Row type="flex" justify="space-around" align="middle">
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={8}
            style={{ paddingTop: 10 }}
          >
            <img
              alt=""
              className="imgDesktop"
              src="./assets/fotoPalestra.png"
            />
            <img alt="" className="imgMobile" src="./assets/fotoMobile2.png" />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xxl={16}>
          <br /> Entretanto o direito Médico vem dedicando uma melhor atenção,
            com atuação tanto na documentação pré-processual, bem como, na
            defesa do profissional no âmbito processual.
          </Col>
        </Row>
      </Row>
      <Row
        id="contato"
        style={{ minHeight: "100vh", padding: 50, paddingTop: 150 }}
      >
        <Row>
          <h1>Entre em contato</h1>
        </Row>
        <ContactForm></ContactForm>
        <Row type="flex" justify="space-around" align="middle">
          <Col span={18}>
            <FontAwesomeIcon
              icon={["fab", "whatsapp"]}
              size="lg"
              style={{ color: "#34af23", fontSize: 25 }}
            />
            <h4>(21) 99989-2354</h4>
          </Col>
          <Col span={18}>
            <FontAwesomeIcon
              icon={["fas", "phone"]}
              size="lg"
              style={{ color: "#3b5998", fontSize: 25 }}
            />
            <h4>(21) 99989-2354</h4>
          </Col>
          <Col span={18}>
            <FontAwesomeIcon
              icon={["fas", "envelope"]}
              size="lg"
              style={{ color: "#4c89e3", fontSize: 25 }}
            />
            <h4>relvas.alexandre@gmail.com</h4>
          </Col>
        </Row>
      </Row>
    </LayoutContent>
  );
};

export default Home;
