import React, { useState } from "react";
import { Form, Input, Button } from "antd";
import FormItem from "antd/lib/form/FormItem";
import MaskedInput from "react-text-mask";
import emailMask from "text-mask-addons/dist/emailMask";

const { TextArea } = Input;

const ContactForm = () => {
  const [buttonText, setButtonText] = useState("Enviar");
  const [sending, setSending] = useState(false);
  const [sendingDone, setSendingDone] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = e => {
    e.preventDefault();
    setButtonText("Enviando...");
    setSending(true);

    const data = {
      name,
      email,
      phone,
      message
    };

    fetch(process.env.REACT_APP_API_CONTACT_ENDPOINT, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data)
    })
      .then(res => {
        if (res.status !== 200) {
          throw res;
        }
        console.log("ok");
        setButtonText("Mensagem enviada!");
      })
      .catch(e => {
        console.log(e);
        setButtonText("Envio falhou. Tente novamente mais tarde!");
      })
      .finally(() => {
        setSending(false);
        setSendingDone(true);
      });
  };

  return (
    <Form
      labelCol={{ xs: { span: 24 }, sm: { span: 8 } }}
      wrapperCol={{ xs: { span: 24 }, sm: { span: 8 } }}
      onSubmit={handleSubmit}
    >
      <FormItem label="Nome" labelCol={{ span: 8 }} wrapperCol={{ span: 8 }}>
        <Input required onChange={e => setName(e.target.value)} />
      </FormItem>
      <Form.Item label="Email">
        <MaskedInput
          mask={emailMask}
          className="ant-input"
          onChange={e => setEmail(e.target.value)}
        />
      </Form.Item>
      <Form.Item label="Telefone">
        <MaskedInput
          mask={[
            "(",
            /[1-9]/,
            /\d/,
            ")",
            " ",
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            "-",
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d?/
          ]}
          guide={false}
          className="ant-input"
          required
          onChange={e => setPhone(e.target.value)}
        />
      </Form.Item>
      <Form.Item label="Mensagem">
        <TextArea rows={4} onChange={e => setMessage(e.target.value)} />
      </Form.Item>
      <Form.Item
        wrapperCol={{ xs: 24, sm: 3 }}
        type="flex"
        justify="space-around"
        align="middle"
      >
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          block
          loading={sending && !sendingDone}
          disabled={!sending && sendingDone}
        >
          {buttonText}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ContactForm;
