import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-scroll";

import DrawerToggleButton from "./SideDrawer/DrawerToggleButton";
import SideDrawer from "./SideDrawer/SideDrawer";
import BackDrop from "./BackDrop/BackDrop";

// import Footer from './footer'
import { Layout, Row, Col } from "antd";

const { Footer, Content, Header } = Layout;

const LayoutContent = ({ children, history }) => {
  const [sideDrawer, setSideDrawer] = useState(false);
  let backDrop;

  if (sideDrawer) {
    backDrop = <BackDrop onClick={setSideDrawer} />;
  }

  return (
    <Layout className="layout">
      <Header
        className="header"
        style={{
          height: "120px",
          position: "fixed",
          width: "100%",
          zIndex: 1,
        }}
      >
        <img className="logo" alt="" src="./assets/logoFinalADV.png" />
        <img className="logoMobile" alt="" src="./assets/logoMobile.png" />
        <div className="spacer"></div>
        <div className="navigation__items">
          <Link
            activeClass="active"
            to="inicio"
            spy={true}
            smooth={true}
            offset={-150}
            duration={500}
          >
            Início
          </Link>
          <Link
            activeClass="active"
            to="sobre"
            spy={true}
            smooth={true}
            offset={-90}
            duration={500}
          >
            Sobre mim
          </Link>
          <Link
            activeClass="active"
            to="contato"
            spy={true}
            smooth={true}
            offset={-20}
            duration={500}
          >
            Contato
          </Link>
        </div>
        <DrawerToggleButton onClick={setSideDrawer} />
      </Header>
      <SideDrawer show={sideDrawer} />
      {backDrop}
      <Content className="content" style={{ paddingTop: 150 }}>
        {children}
      </Content>
      <Footer>
        <Row>
          <Col span={22} style={{ textAlign: "center", color: "#2a2e37" }}>
            &copy;
            <a className="linkAlexandre" href="www.alexandrerelvas.com.br">
              ALEXANDRE RELVAS ADVOCACIA
            </a>{" "}
            - TODOS OS DIREITOS RESERVADOS
          </Col>
          {/* <Col xs={12} lg={1}>
            <a
              className="socials-link-face"
              href="https://www.facebook.com/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon
                icon={["fab", "facebook"]}
                size="lg"
                style={{ fontSize: 25 }}
              />
            </a>
          </Col> */}
          <Col xs={12} lg={2} style={{ padding: 0, margin: 0, textAlign: "right"}}>
            <a
              className="socials-link-insta"
              href="https://www.instagram.com/alexandre_relvas/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon
                icon={["fab", "instagram"]}
                size="lg"
                style={{ fontSize: 25 }}
              />
            </a>
          </Col>
        </Row>
      </Footer>
    </Layout>
  );
};

export default withRouter(LayoutContent);
