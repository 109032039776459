import React from "react";
import "./App.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";

import { Route, BrowserRouter as Router } from "react-router-dom";

import Home from "./pages/Home";

library.add(fab, faPhone, faEnvelope);

function App() {
  return (
    <div>
      <Router>
        <Route exact path="/" component={Home}></Route>
      </Router>
    </div>
  );
}

export default App;
