import React from "react";
import { Icon } from "antd";

import "./DrawerToggleButton.css";

const drawerToggleButton = props => (
  <button className="toggle-button" onClick={props.onClick}>
    <Icon className="icon" type="menu" />
  </button>
);

export default drawerToggleButton;
