import React from "react";
import { Link } from "react-scroll";

import "./SideDrawer.css";

const DrawerContent = props => {
  let drawerClasses = "side-drawer";
  if (props.show) {
    drawerClasses = "side-drawer open";
  }

  return (
    <nav className={drawerClasses}>
      <div className="links-drawer">
        <Link
          activeClass="active"
          to="inicio"
          spy={true}
          smooth={true}
          offset={-150}
          duration={500}
        >
          Início
        </Link>
        <Link
          activeClass="active"
          to="sobre"
          spy={true}
          smooth={true}
          offset={-90}
          duration={500}
        >
          Sobre mim
        </Link>
        <Link
          activeClass="active"
          to="contato"
          spy={true}
          smooth={true}
          offset={-20}
          duration={500}
        >
          Contato
        </Link>
      </div>
    </nav>
  );
};

export default DrawerContent;
